import axios from 'axios'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  BASE_API: '"http://localhost:10066/java"',
  timeout: 5000 // 请求超时时间
})

export default service
